import { UtilActions as UA } from "./Actions";

export const UtilReducer = (state, action) => {
  switch (action.type) {
    case UA.SHOW_HEADER:
      return {
        ...state,
        headerVisible: true,
      };
    case UA.HIDE_HEADER:
      return {
        ...state,
        headerVisible: false,
      };
    case UA.SHOW_FOOTER:
      return {
        ...state,
        footerVisible: true,
      };
    case UA.HIDE_FOOTER:
      return {
        ...state,
        footerVisible: false,
      };

    case UA.SHOW_WALLET_BOX:
      return {
        ...state,
        isDarkLayoutOn: true,
        isModelOn: true,
        connectWalletPopup: true,
      };
    case UA.HIDE_WALLET_BOX:
      return {
        ...state,
        isDarkLayoutOn: false,
        isModelOn: false,
        connectWalletPopup: false,
      };
    case UA.SHOW_WRONG_NETWORK_BOX:
      return {
        ...state,
        isDarkLayoutOn: true,
        isModelOn: true,
        wrongNetworkPopup: true,
      };
    case UA.HIDE_WRONG_NETWORK_BOX:
      return {
        ...state,
        isDarkLayoutOn: false,
        isModelOn: false,
        wrongNetworkPopup: false,
      };
    case UA.SHOW_TRANSACTION_BOX:
      return {
        ...state,
        isDarkLayoutOn: true,
        isModelOn: true,
        transactionProgress: true,
        transactionText: action.payload.main,
        extra1: action.payload.extra1,
        extra2: action.payload.extra2
      };
    case UA.HIDE_TRANSACTION_BOX:
      return {
        ...state,
        isDarkLayoutOn: false,
        isModelOn: false,
        transactionProgress: false,
      };
    case UA.SHOW_PURCHASE_BOX:
      return {
        ...state,
        isDarkLayoutOn: true,
        isModelOn: true,
        purchaseNftPopup: true,
        purchaseData: action.payload
      };
    case UA.HIDE_PURCHASE_BOX:
      return {
        ...state,
        isDarkLayoutOn: false,
        isModelOn: false,
        purchaseNftPopup: false,
      };
    case UA.SHOW_ON_IPFS:
      return {
        ...state,
        isDarkLayoutOn: true,
        isModelOn: true,
        ipfsPopup: true,
        ipfsData: action.payload
      };
    case UA.HIDE_ON_IPFS:
      return {
        ...state,
        isDarkLayoutOn: false,
        isModelOn: false,
        ipfsPopup: false,
      };
    case UA.SHOW_COOKIE_MODEL:
      return {
        ...state,
        isDarkLayoutOn: true,
        isModelOn: true,
        cookieModel: true
      };
    case UA.HIDE_COOKIE_MODEL:
      return {
        ...state,
        isDarkLayoutOn: false,
        isModelOn: false,
        cookieModel: false,
      };
    case UA.SET_WALLET_TYPE:
      return {
        ...state,
        walletType: action.payload
      };
  }
};
