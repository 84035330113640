export const UtilInitialState = {
  headerVisible: true,
  footerVisible: true,
  isDarkLayoutOn: false,
  isModelOn: false,
  connectWalletPopup: false,
  wrongNetworkPopup: false,
  transactionProgress: false,
  transactionText: "",
  extra1: "",
  extra2: "",
  purchaseNftPopup: false,
  ipfsPopup: false,
  ipfsData: {},
  purchaseData: {},
  walletType: "",
  cookieModel: false
};
