import Store from '../Contexts/Store';
import '../styles/_globals.scss';
import { useEffect } from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import ReactGA from 'react-ga4';
import Script from 'next/script';
import "react-loading-skeleton/dist/skeleton.css"
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
const NFTApp = ({ Component, pageProps }) => {
  const hotjar = `
!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_n26rm8oz', {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"<AAID-HERE>","email":"<EMAIL-HERE>","externalId":"<EXTERNAL-ID-HERE>","idfa":"<IDFA-HERE>"});rdt('track', 'PageVisit');
`;

  useEffect(() => {
    if(!ReactGA._hasLoadedGA)
    {
      ReactGA.initialize('G-KD4Q8R89S4');
      ReactGA.send('pageview');
    }
  }, []);

  function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  }

  return (
    <Store>
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: hotjar }}
      />
      <Web3ReactProvider getLibrary={getLibrary}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Component {...pageProps} />
        </MuiPickersUtilsProvider>
      </Web3ReactProvider>
    </Store>
  );
};

export default NFTApp;
