import { AuthActions as AA } from "./Actions";

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case AA.LOGIN:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
        isAuthenticated: true,
      };
    case AA.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    case AA.VALIDATE_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AA.SET_SEARCH_LIST_SHOW:
      return {
        ...state,
        showList: action.payload,
      };
    case AA.SET_SEARCHPARAMETER:
      return {
        ...state,
        searchParameter: action.payload,
      };
  }
};
