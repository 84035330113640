import { NFTActions } from './Actions';
let initailNftForm = {
  is_collection: 0,
  category: '',
  assets: [],
  collection_thumb: null,
  extra_music: null,
  price: '',
  unlock_data: '',
  on_resell: 1,
  title: '',
  description: '',
  royalty: 10,
  total_copies: 1,
  props: '',
  alt_text: '',
};
export const nftReducer = (state, action) => {
  switch (action.type) {
    case NFTActions.ADD_FAV:
      localStorage.setItem(
        'favNFT',
        JSON.stringify({
          ...state,
          ...action['payload'],
        })
      );
      return {
        ...state,
        ...action['payload'],
      };
    case NFTActions.REMOVE_FAV:
      localStorage.setItem(
        'favNFT',
        JSON.stringify({
          ...state,
          ...action['payload'],
        })
      );
      return {
        ...state,
      };
    case NFTActions.SET_CREATE_NFT_DETAILS:
      let nftDetailsObj = { ...state.nftFormDetails, ...action.payload };
      return {
        ...state,
        nftFormDetails: nftDetailsObj,
      };
    case NFTActions.SET_EXPLORE_NFT_DATA:
      let exploreData = [...action.payload.data];
      let explorePaginationData = {
        page_no: 0 + 1,
        page_size: 12,
        count: action.payload.total,
        currentCount: 12,
      };
      // let explorePaginationData = {
      //   page_no: 0,
      //   page_size: 9,
      //   count: 0,
      //   currentCount: 0,
      // };
      return {
        ...state,
        explore: exploreData,
        explorePagination: explorePaginationData,
      };
    case NFTActions.ADD_NFT_DATA_EXPLORE:
      let expoloreViewMore = [...state.explore, ...action.payload.data];
      let explorePaginationDataMore = {
        page_no: state.explorePagination.page_no + 1,
        page_size: 12,
        count: action.payload.total,
        currentCount: state.explorePagination.currentCount + 9,
      };
      return {
        ...state,
        explore: expoloreViewMore,
        explorePagination: explorePaginationDataMore,
      };
    case NFTActions.SET_TOP_RATED_NFT_DATA:
      let topRatedData = [...action.payload];
      return {
        ...state,
        topRated: topRatedData,
      };
    case NFTActions.SET_GALLERY_NFT_DATA:
      let topGalleryData = [...action.payload];
      return {
        ...state,
        topGalleryData: topGalleryData,
      };
    case NFTActions.SET_GIVEAWAY_NFT_DATA:
      let topGiveawayData = [...action.payload];
      return {
        ...state,
        topGiveawayData: topGiveawayData,
      };
    case NFTActions.SET_CATAGORY_NFT:
      return {
        ...state,
        catagorySelected: action.payload,
      };
    case NFTActions.SET_CREATED_NFT:
      let createdList = [...state.created, ...action.payload.data];
      let createdPaginationSet = {
        page_no: state.createdPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.createdPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        created: createdList,
        createdPagination: createdPaginationSet,
      };
    case NFTActions.SET_CREATED_NFT_INITIAL:
      let createdListInitail = [...action.payload.data];

      let createdPaginationSetInital = {
        page_no: state.createdPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.createdPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        created: createdListInitail,
        createdPagination: createdPaginationSetInital,
      };

      /* -----------------published ai----------------------------- */
      case NFTActions.SET_PUBLISHED_AI_NFT:
      let publishedAIList = [...state.publishedAI, ...action.payload.data];
      let publishedAIPaginationSet = {
        page_no: state.publishedAIPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.publishedAIPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        publishedAI: publishedAIList,
        publishedAIPagination: publishedAIPaginationSet,
      };
    case NFTActions.SET_PUBLISHED_AI_NFT_INITIAL:
      let publishedAIListInital = [...action.payload.data];
      let publishedAIPaginationSetInital = {
        page_no: state.publishedAIPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.publishedAIPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        publishedAI: publishedAIListInital,
        publishedAIPagination: publishedAIPaginationSetInital,
      };

      case NFTActions.SET_PAGINATION_PUBLISHED_AI_INTIAL:
      let publishedAIPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        publishedAIPagination: publishedAIPaginationSetInitalTime,
      };
/* -----------------saved ai----------------------------- */
      case NFTActions.SET_SAVED_AI_NFT:
      let savedAIList = [...state.savedAI, ...action.payload.data];
      let savedAIPaginationSet = {
        page_no: state.savedAIPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.savedAIPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        savedAI: savedAIList,
        savedAIPagination: savedAIPaginationSet,
      };
    case NFTActions.SET_SAVED_AI_NFT_INITIAL:
      let savedAIListInital = [...action.payload.data];
      let savedAIPaginationSetInital = {
        page_no: state.savedAIPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.savedAIPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        savedAI: savedAIListInital,
        savedAIPagination: savedAIPaginationSetInital,
      };

      case NFTActions.SET_PAGINATION_SAVED_AI_INTIAL:
      let savedAIPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        savedAIPagination: savedAIPaginationSetInitalTime,
      };
/* ------------------------------------------ */
    case NFTActions.SET_PAGINATION_CREATED_INTIAL:
      let createdPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        createdPagination: createdPaginationSetInitalTime,
      };
    case NFTActions.SET_COLLECTED_NFT:
      let cooltedList = [...state.collected, ...action.payload.data];
      let collectedPaginationSet = {
        page_no: state.collectedPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.collectedPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        collected: cooltedList,
        collectedPagination: collectedPaginationSet,
      };
    case NFTActions.SET_COLLECTED_NFT_INITIAL:
      let cooltedListInital = [...action.payload.data];
      let collectedPaginationSetInital = {
        page_no: state.collectedPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.collectedPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        collected: cooltedListInital,
        collectedPagination: collectedPaginationSetInital,
      };
    case NFTActions.SET_PAGINATION_COLLECTED_INTIAL:
      let collectedPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        collectedPagination: collectedPaginationSetInitalTime,
      };
    case NFTActions.SET_SAVE_ITEM_NFT:
      let favList = [...state.fav, ...action.payload.data];
      let favPaginationSet = {
        page_no: state.favPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.favPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        fav: favList,
        favPagination: favPaginationSet,
      };
    case NFTActions.SET_SAVE_ITEM_NFT_INITIAL:
      let favListInital = [...action.payload.data];
      let favPaginationSetInitail = {
        page_no: state.favPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.favPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        fav: favListInital,
        favPagination: favPaginationSetInitail,
      };
    case NFTActions.SET_PAGINATION_SAVE_ITEM_NFT_INTIAL:
      let favPaginationSetInitailTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        favPagination: favPaginationSetInitailTime,
      };
    case NFTActions.SET_SOLD_NFT:
      let soldOutList = [...state.soldOut, ...action.payload.data];
      let soldOutPaginationSet = {
        page_no: state.soldOutPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.soldOutPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        soldOutPagination: soldOutPaginationSet,
        soldOut: soldOutList,
      };
    case NFTActions.SET_SOLD_NFT_INITIAL:
      let soldOutListInital = [...action.payload.data];
      let soldOutPaginationSetInital = {
        page_no: state.soldOutPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.soldOutPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        soldOutPagination: soldOutPaginationSetInital,
        soldOut: soldOutListInital,
      };
    case NFTActions.SET_PAGINATION_SOLD_NFT_INTIAL:
      let soldOutPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        soldOutPagination: soldOutPaginationSetInitalTime,
      };
    case NFTActions.SET_GALLERY_NFT:
      let galleryList = [...state.gallery, ...action.payload.data];
      let galleryPaginationSet = {
        page_no: state.galleryPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.galleryPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        galleryPagination: galleryPaginationSet,
        gallery: galleryList,
      };
    case NFTActions.SET_GALLERY_INITIAL:
      let galleryListInital = [...action.payload.data];
      let galleryPaginationSetInital = {
        page_no: state.galleryPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.galleryPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        galleryPagination: galleryPaginationSetInital,
        gallery: galleryListInital,
      };
    case NFTActions.SET_PAGINATION_GALLERY_INTIAL:
      let galleryPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        galleryPagination: galleryPaginationSetInitalTime,
      };
    // Giveaway Start

    case NFTActions.SET_GIVEAWAY_NFT:
      let giveawayList = [...state.giveaway, ...action.payload.data];
      let giveawayPaginationSet = {
        page_no: state.giveawayPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.giveawayPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        giveawayPagination: giveawayPaginationSet,
        giveaway: giveawayList,
      };
    case NFTActions.SET_GIVEAWAY_INITIAL:
      let giveawayListInital = [...action.payload.data];
      let giveawayPaginationSetInital = {
        page_no: state.giveawayPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.giveawayPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        giveawayPagination: giveawayPaginationSetInital,
        giveaway: giveawayListInital,
      };
    case NFTActions.SET_PAGINATION_GIVEAWAY_INTIAL:
      let giveawayPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        giveawayPagination: giveawayPaginationSetInitalTime,
      };

    // Giveaway End
    case NFTActions.SET_SALES_NFT:
      let salesList = [...state.sales, ...action.payload.data];
      let salesPaginationSet = {
        page_no: state.salestPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.salestPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        salestPagination: salesPaginationSet,
        sales: salesList,
      };
    case NFTActions.SET_SALES_NFT_INITIAL:
      let salesListInital = [...action.payload.data];
      let salesPaginationSetInital = {
        page_no: state.salestPagination.page_no + 1,
        page_size: 9,
        count: action.payload.total,
        currentCount:
          state.salestPagination.currentCount + action.payload.data.length,
      };
      return {
        ...state,
        salestPagination: salesPaginationSetInital,
        sales: salesListInital,
      };
    case NFTActions.SET_PAGINATION_SALES_NFT_INTIAL:
      let salesPaginationSetInitalTime = {
        page_no: 0,
        page_size: 0,
        count: 0,
        currentCount: 0,
      };
      return {
        ...state,
        salestPagination: salesPaginationSetInitalTime,
      };
    case NFTActions.GET_NFT:
      let nftDetails = { ...state.nftDetails, ...action.payload };
      return {
        ...state,
        nftPageDetails: nftDetails,
      };
    case NFTActions.CLEAR_NFT_DETAIL:
      return {
        ...state, 
        nftPageDetails: {}
      };
    case NFTActions.CLR_NFT_DETAILS:
      return {
        ...state,
        nftFormDetails: initailNftForm,
      };
    case NFTActions.UPDATE_NFT_DETAILS:
      // Update Top Rated Data update
      let getTopId = state.topRated.findIndex(
        (data) => data.token === action.payload.token
      );
      let topratedListForUpdate = [...state.topRated];
      if (getTopId !== -1) {
        topratedListForUpdate[getTopId] = action.payload;
      }
      if (getTopId !== -1 && action.payload?.metadata?.is_listed === 0) {
        topratedListForUpdate.splice(getTopId, 1);
      }
      // Update exploreData
      let exploreId = state.explore.findIndex(
        (data) => data.token === action.payload.token
      );
      let exploreListForUpdate = [...state.explore];
      if (exploreId !== -1) {
        exploreListForUpdate[exploreId] = action.payload;
      }
      if (exploreId !== -1 && action.payload?.metadata?.is_listed === 0) {
        exploreListForUpdate.splice(exploreId, 1);
      }
      // Update Created
      let createdId = state.created.findIndex(
        (data) => data.token === action.payload.token
      );
      
      /* saved */
      let savedAIId = state.savedAI.findIndex(
        (data) => data.token === action.payload.token
      );
      let SavedAIListForUpdate = [...state.savedAI];
      if (savedAIId !== -1) {
        SavedAIListForUpdate[savedAIId] = action.payload;
      }

      /* published */
      let publishedAIId = state.publishedAI.findIndex(
        (data) => data.token === action.payload.token
      );
      let PublishedAIListForUpdate = [...state.publishedAI];
      if (publishedAIId !== -1) {
        PublishedAIListForUpdate[publishedAIId] = action.payload;
      }


      let createdListForUpdate = [...state.created];
      if (createdId !== -1) {
        createdListForUpdate[createdId] = action.payload;
      }
      // Update Collected
      let collecteddId = state.collected.findIndex(
        (data) => data.token === action.payload.token
      );
      let CollectedListForUpdate = [...state.collected];
      if (collecteddId !== -1) {
        CollectedListForUpdate[collecteddId] = action.payload;
      }
      // Update Fav
      let favId = state.fav.findIndex(
        (data) => data.token === action.payload.token
      );
      let favListForUpdate = [...state.fav];
      if (favId !== -1) {
        favListForUpdate[favId] = action.payload;
      }
      if (favId !== -1 && action.payload?.metadata?.is_fav === 0) {
        favListForUpdate.splice(favId, 1);
      }
      if (favId === -1 && action.payload?.metadata?.is_fav === 1) {
        favListForUpdate.push(action.payload);
      }
      // Update Soldout
      let soldoutId = state.soldOut.findIndex(
        (data) => data.token === action.payload.token
      );
      let solOutForUpdate = [...state.soldOut];
      if (soldoutId !== -1) {
        solOutForUpdate[soldoutId] = action.payload;
      }
      // Update Sale
      let saleId = state.sales.findIndex(
        (data) => data.token === action.payload.token
      );
      let salesForUpdate = [...state.sales];
      if (saleId === -1 && action.payload?.metadata?.is_listed !== 0) {
        salesForUpdate.push(action.payload);
      }
      if (saleId !== -1 && action.payload?.metadata?.is_listed !== 0) {
        salesForUpdate[saleId] = action.payload;
      }
      if (saleId !== -1 && action.payload?.metadata?.is_listed === 0) {
        salesForUpdate.splice(saleId, 1);
      }
       // Update Top Gallery Data
      let getTopGalleryId = state.topGalleryData.findIndex(
        (data) => data.token === action.payload.token
      );
      let topratedGalleryListForUpdate = [...state.topGalleryData];
      if (getTopGalleryId !== -1) {
        topratedGalleryListForUpdate[getTopGalleryId] = action.payload;
      }
      if (getTopGalleryId !== -1 && action.payload?.metadata?.is_listed === 0) {
        topratedGalleryListForUpdate.splice(getTopGalleryId, 1);
      }
      // Update Top Giveaway Data
      let getTopGiveawayId = state.topGiveawayData.findIndex(
        (data) => data.token === action.payload.token
      );
      let topratedGiveawayListForUpdate = [...state.topGiveawayData];
      if (getTopGiveawayId !== -1) {
        topratedGiveawayListForUpdate[getTopGiveawayId] = action.payload;
      }
      if (getTopGiveawayId !== -1 && action.payload?.metadata?.for_giveaway === 0) {
        topratedGiveawayListForUpdate.splice(getTopGiveawayId, 1);
      }


      let topCollectedCart = state.topCollectionData.findIndex(
        (data) => data.token === action.payload.token
      );
      let topCollectedCartForUpdate = [...state.topCollectionData];
      if (topCollectedCart !== -1) {
        topCollectedCartForUpdate[topCollectedCart] = action.payload;
      }
      let cartListDatas = state.cartListData.findIndex(
        (data) => data.token === action.payload.token
      );
      let cartListForUpdate = [...state.cartListData];
      if (cartListDatas !== -1) {
        cartListForUpdate[cartListDatas] = action.payload;
      }

     

      return {
        ...state,
        topRated: topratedListForUpdate,
        topGalleryData: topratedGalleryListForUpdate,
        topGiveawayData: topratedGiveawayListForUpdate,
        explore: exploreListForUpdate,
        created: createdListForUpdate,
        collected: CollectedListForUpdate,
        fav: favListForUpdate,
        soldOut: solOutForUpdate,
        sales: salesForUpdate,
        savedAI: SavedAIListForUpdate,
        publishedAI: PublishedAIListForUpdate,
        topCollectionData: topCollectedCartForUpdate,
        updateTopCollectedData: topCollectedCartForUpdate,
        cartListData: cartListForUpdate,
        updateCartListData: cartListForUpdate,
        
      };
    case NFTActions.DELETE_NFT_DATA:
      // Update Top Rated Data update
      let getTopIdDelete = state.topRated.findIndex(
        (data) => data.token === action.payload.token
      );
      let topratedListForUpdateDelete = [...state.topRated];
      if (getTopIdDelete !== -1) {
        topratedListForUpdateDelete.splice(getTopIdDelete, 1);
      }
      // Update exploreData
      let exploreIdDelete = state.explore.findIndex(
        (data) => data.token === action.payload.token
      );
      let exploreListForUpdateDelete = [...state.explore];
      if (exploreIdDelete !== -1) {
        exploreListForUpdateDelete.splice(exploreIdDelete, 1);
      }
      // Update Created
      let createdIdDelete = state.created.findIndex(
        (data) => data.token === action.payload.token
      );
      let createdListForUpdateDelete = [...state.created];
      if (createdIdDelete !== -1) {
        createdListForUpdateDelete.splice(createdIdDelete, 1);
      }
      // Update Collected
      let collecteddIdDelete = state.collected.findIndex(
        (data) => data.token === action.payload.token
      );
      let CollectedListForUpdateDelete = [...state.collected];
      if (collecteddIdDelete !== -1) {
        CollectedListForUpdateDelete.splice(collecteddIdDelete, 1);
      }
      // Update Fav
      let favIdDelete = state.fav.findIndex(
        (data) => data.token === action.payload.token
      );
      let favListForUpdateDelete = [...state.fav];
      if (favIdDelete !== -1) {
        favListForUpdateDelete.splice(favIdDelete, 1);
      }
      // Update Soldout
      let soldoutIdDelete = state.soldOut.findIndex(
        (data) => data.token === action.payload.token
      );
      let solOutForUpdateDelete = [...state.soldOut];
      if (soldoutIdDelete !== -1) {
        solOutForUpdateDelete.splice(soldoutIdDelete, 1);
      }
      // Update Sale
      let saleIdDelete = state.sales.findIndex(
        (data) => data.token === action.payload.token
      );
      let salesForUpdateDelete = [...state.sales];
      if (saleIdDelete !== -1) {
        salesForUpdateDelete.splice(saleIdDelete, 1);
      }
      return {
        ...state,
        topRated: topratedListForUpdateDelete,
        explore: exploreListForUpdateDelete,
        created: createdListForUpdateDelete,
        collected: CollectedListForUpdateDelete,
        fav: favListForUpdateDelete,
        soldOut: solOutForUpdateDelete,
        sales: salesForUpdateDelete,
      };
    case NFTActions.SET_HEADER_DATA:
      return {
        ...state,
        headerData: action.payload,
      };
    case NFTActions.SET_SEARCH_NFT_DATA:
      return {
        ...state,
        items: action.payload.items,
        useres: action.payload.users,
        collections: action.payload.collections,
      };

      case NFTActions.SET_TOP_COLLECTION_NFT_DATA:

      return {
        ...state,
        topCollectionData: action.payload
      };

      case NFTActions.SET_CART_LIST_DATA:
      return {
        ...state,
        cartListData: action.payload
      };

      case NFTActions.UPDATE_NFT_DATA:
      return {
        ...state,
        updateNftData: action.payload
      };

    default:
      return {
        ...state,
      };
  }
};

// GET_NFT: "GET_NFT",
// ADD_FAV: "ADD_FAV",
// DELETE_NFT: "DELETE_NFT",
// PURCHASE_NFT: "PURCHASE_NFT",
// SIGN_NFT: "SIGN_NFT",
