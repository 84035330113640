import { UserActions as UA } from "./Actions";
let clrProfileData = {
  bio: "",
  email: "",
  facebook_url: "",
  id: 11,
  instagram_url: "",
  is_profile_completed: 0,
  is_verified: 0,
  is_your: 0,
  name: "",
  nonce: 0,
  profile_photo: "",
  profile_photo_thumb: "",
  public_address: "",
  social_follower_cnt: "",
  twitter_url: "",
  username: "",
  website_url: "",
};
export const UserReducer = (state, action) => {
  switch (action.type) {
    case UA.SIGN_NOUNCE:
      return {
        ...state,
        userProfileData: {
          ...state.userProfileData,
          ...action.payload,
        },
      };
    case UA.GET_PROFILE:
      return {
        ...state,
        userProfileData: {
          ...state.userProfileData,
          ...action.payload,
        },
      };
    case UA.CLR_PROFILE_PAGE_DATA:
      return {
        ...state,
        userprofilePageData: clrProfileData,
        userProfileData: state.userProfileData,
      };
    case UA.SET_USER_PROFILE_PAGEDATA:
      return {
        ...state,
        userprofilePageData: {
          ...state.userprofilePageData,
          ...action.payload,
        },
      };
    case UA.UPDATE_PROFILE:
      return {
        ...state,
        userProfileData: {
          ...state.userProfileData,
          ...action.payload,
        },
      };
  }
};
