const NFT_TYPES = {
  ALL: {
    title: "All",
    icon: "/assets/vectors/Vector_cat_all.svg",
    allowedFileType: ["All"],
  },
  ART: {
    title: "Art",
    icon: "/assets/vectors/Vector_cat_art.svg",
    allowedFileType: ["png", "jpg", "webp", "tiff", "gif"],
  },
  MUSIC: {
    title: "Music",
    icon: "/assets/vectors/Vector_cat_music.svg",
    allowedFileType: ["mp3", "flac", "wav"],
  },
  VIDEO: {
    title: "Video",
    icon: "/assets/vectors/Vector_cat_video.svg",
    allowedFileType: ["mp4"],
  },
  PHOTOGRAPHY: {
    title: "Photography",
    icon: "/assets/vectors/Vector_cat_photo.svg",
    allowedFileType: ["png", "jpg", "webp", "tiff", "gif"],
  },
  CC_ART: {
    title: "CC_Art",
    icon: "/assets/vectors/Vector_cat_cc.png",
    allowedFileType: ["png", "jpg", "webp", "tiff", "gif"],
  },
  VC_ART: {
    title: "VC_Art",
    icon: "/assets/vectors/Vector_cat_vc.png",
    allowedFileType: ["png", "jpg", "webp", "tiff", "gif"],
  },
};

export default NFT_TYPES;
