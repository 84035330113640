import React from "react";
import Cookies from "universal-cookie";
import { AuthActions as AA } from "./Actions";
import { AuthReducer as AR } from "./Reducer";
import { AuthInitialState as AIS } from "./State";
export const AuthContext = React.createContext({ AIS, dispatch: () => null });
const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AR, AIS);
  const cookies = new Cookies();
  const setAuthCookies = (data) => {
    if (cookies) {
      cookies.set("tokens", data, { path: "/" });
    }
  };
  const checkCookies = () => {
    if (cookies.get("tokens")) {
      dispatch({
        type: AA.VALIDATE_LOGIN,
        payload: cookies.get("tokens"),
      });
    }
  };

  const disconnetWallet = () => {
    if (cookies.get("tokens")) {
      cookies.remove("tokens", { path: '/' });
    }
    if (state.isAuthenticated) {
      dispatch({
        type: AA.LOGOUT,
      });
    }
    // Router.reload();
  };

  //Temp
  React.useEffect(() => {
    checkCookies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ authState: state, setAuthCookies, disconnetWallet, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
