
import Interceptors from "../interceptors";

let interceptores = new Interceptors();

export const getProfile = async (data) => {
  return interceptores.GET(`users/get-profile`);
};
export const getProfileFromPubAdd = async (data) => {
  return interceptores.GET(`users/get-profile?public_address=${data}`);
};
export const editProfilePic = async (data) => {
  return interceptores.POST("users/profile-photo", data);
};
export const editProfile = async (data) => {
  return interceptores.PATCH("users/edit-profile", data);
};
export const verifyEmail = async (data) => {
  return interceptores.PATCH("users/verify", data);
};
export const userTradingHistory = async (data) => {
  return interceptores.GET(`users/history?public_address=${data}`);
};
export const contactusApi = async (data) => {
  return interceptores.POST(`users/contactus`, data);
};
export const getCmsData = async (data) => {
  return interceptores.GET(`users/cms?key=${data}`);
};
export const getSettings = async (data) => {
  return interceptores.GET(`users/settings`);
};
export const getMarketingPromoters = async (data) => {
  return interceptores.GET(`users/get-dynamic-clients`);
}
export const getReferralLink = async (data) => {
  return interceptores.GET(`users/get-referral-link`);
};
export const getReferredUsers = async (data) => {
  return interceptores.GET(`users/get-referred-users?page_no=${data.page_no}&page_size=${data.page_size}&sort_by_date=${data.sort_by_date}&sort_by_name=${data.sort_by_name}`);
};
// Gems
export const getGemsPlan = async (data) => {
  return interceptores.GET(`gems/get-gems-plan`);
};
export const purchaseGems = async (data) => {
  return interceptores.POST(`gems/purchase-gems`,data);
};
export const getCollectedGems = async (data) => {
  return interceptores.GET(`gems/get-collected-gems?page_no=${data.page_no}&page_size=${data.page_size}&gems_type=${data.gems_type}
  &gems_for=${data.gems_for}&sort_by_gems=${data.sort_by_gems}&sort_by_date=${data.sort_by_date}&sort_by_action=${data.sort_by_action}&sort_by_cd=${data.sort_by_cd}`);
};
export const getPurchasedGems = async (data) => {
  return interceptores.GET(`gems/get-purchased-gems?page_no=${data.page_no}&page_size=${data.page_size}&purchase_using=${data.purchase_using}
  &sort_by_deducted=${data.sort_by_deducted}&sort_by_gems=${data.sort_by_gems}&sort_by_date=${data.sort_by_date}`);
};
export const updateGemsForShare = async (data) => {
  return interceptores.POST(`gems/update-gems-for-share`,data);
};
// Total Collected Gems Data
export const getTotalCollectedGemsData = async (data) => {
  return interceptores.GET(`gems/get-total-collected-gems`);
};
// Bid
export const createBid = async (data) => {
  return interceptores.POST(`bid/create-bid`,data);
};
export const updateBid = async (data) => {
  return interceptores.POST(`bid/update-bid`,data);
};
export const getHomePageCurrentBid = async (data) => {
  return interceptores.GET(`bid/get-homepage-current-bid`);
};
export const setAsSpotlightAPICall = async (data) => {
  return interceptores.POST(`bid/set-as-spotlight`,data);
};
export const bidHistory = async (data) => {
  return interceptores.POST(`bid/get-bid-history`,data);
};
export const getCurrentBid = async (data) => {
  return interceptores.POST(`bid/get-current-bid`,data);
};
export const updateBidMasterStatus = async (data) => {
  return interceptores.GET(`bid/update-bid-master-status`);
};

export const updateAndGetNewBidRecords = async (data) => {
  return interceptores.POST(`bid/update-and-get-new-bid-records`,data);
};

export const getUserWalletBalance = async (req) => {
  return interceptores.POST(`users/wallet-balance`, req);
};
