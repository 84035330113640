import { useRouter } from "next/router";
import React from "react";
// import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import Cookies from "universal-cookie";
// import { getProfile } from "../../lib/User";
import { getProfile } from "../../lib/User/index";
import UseAuth from "../Auth/useAuth";
import { UserActions as UA } from "./Actions";
import { UserReducer as UR } from "./Reducer";
import { UserInitialState as UIS } from "./State";

export const UserContext = React.createContext({
  UIS,
  dispatch: () => null,
});

const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(UR, UIS);
  const { authState, logoutUser } = UseAuth();
  const cookies = new Cookies();
  const router = useRouter();
  const checkUserProfileData = async () => {
    if (authState?.isAuthenticated) {
      if (Object.keys(authState?.userInfo).length !== 0) {
        dispatch({ type: UA.GET_PROFILE, payload: authState?.userInfo });
      } else {
        let user = await getProfile(authState?.userInfo?.public_address);
        if (user.status === 200) {
          dispatch({ type: UA.GET_PROFILE, payload: user.data?.data });
        } else {
          if (cookies.get("tokens")) {
            cookies.remove("tokens",{ path: "/" });
          }
          if (authState.isAuthenticated) {
            logoutUser();
          }
          router.push("/");
          // toast.error(`${user.data.message}`);
          Swal.fire({
            icon: 'warning',
            // title: 'Oops...',
            text: `${user.data.message}`,
            timer: 1500
          })
        }
      }
    }
  };
  React.useEffect(() => {
    checkUserProfileData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return (
    <UserContext.Provider value={{ userState: state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
