export const UtilActions = {
  SHOW_HEADER: "SHOW_HEADER",
  HIDE_HEADER: "HIDE_HEADER",
  SHOW_WALLET_BOX: "SHOW_WALLET_BOX",
  HIDE_WALLET_BOX: "HIDE_USER_BOX",
  SHOW_TRANSACTION_BOX: "SHOW_TRANSACTION_BOX",
  HIDE_TRANSACTION_BOX: "HIDE_TRANSACTION_BOX",
  SHOW_PURCHASE_BOX: "SHOW_PURCHASE_BOX",
  HIDE_PURCHASE_BOX: "HIDE_PURCHASE_BOX",
  SHOW_WRONG_NETWORK_BOX: "SHOW_WRONG_NETWORK_BOX",
  HIDE_WRONG_NETWORK_BOX: "HIDE_WRONG_NETWORK_BOX",
  SHOW_FOOTER: "SHOW_FOOTER",
  HIDE_FOOTER: "HIDE_FOOTER",
  SHOW_ON_IPFS: "SHOW_ON_IPFS",
  HIDE_ON_IPFS: "HIDE_ON_IPFS",
  SHOW_COOKIE_MODEL: "SHOW_COOKIE_MODEL",
  HIDE_COOKIE_MODEL: "HIDE_COOKIE_MODEL",
  SET_WALLET_TYPE: "SET_WALLET_TYPE",
};
