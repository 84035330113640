import React from "react";
import { NFTUtilsReducer } from "./Reducer";
import { initialState } from "./State";

export const NFTUtilsContext = React.createContext({
  initialState,
  dispatch: () => null,
});

const NFTUtilsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(NFTUtilsReducer, initialState);

  return (
    <NFTUtilsContext.Provider value={{ nftUtilsState: state, dispatch }}>
      {children}
    </NFTUtilsContext.Provider>
  );
};

export default NFTUtilsProvider;
