import React from "react";
import { UtilReducer as UR } from "./Reducer";
import { UtilInitialState as UTI } from "./State";

export const UtilContext = React.createContext({ UTI, dispatch: () => null });

const UtilProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(UR, UTI);

  return (
    <UtilContext.Provider value={{ utilState: state, dispatch }}>
      {children}
    </UtilContext.Provider>
  );
};

export default UtilProvider;
